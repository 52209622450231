export const MuiTextField = {
  styleOverrides: {
    root: {
      "& .MuiInputBase-root": {
        borderRadius: "1px", // 입력 필드의 모서리를 둥글게
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#bbbbbb", // 기본 테두리 색상
        },
        "&:hover fieldset": {
          borderColor: "#000000", // 호버 시 테두리 색상
        },
        "&.Mui-focused fieldset": {
          borderColor: "#000000", // 포커스 시 테두리 색상
        },
      },
    },
  },
};
