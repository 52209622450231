import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CouponInfo {
  id: string;
  code: string;
  discount: number;
  expirationDate: string;
  // 기타 필요한 쿠폰 관련 필드들...
}

export interface CouponState {
  coupons: CouponInfo[];
  selectedCouponId: string | null;
  isLoading: boolean;
  error: string | null;
  currentcoupon: any;
  checkStatus: "unavailable" | "available" | "used" | null;
  voucherStatus: "success" | "fail" | null;
}

const initialState: CouponState = {
  coupons: [],
  selectedCouponId: null,
  isLoading: false,
  error: null,
  currentcoupon: null,
  checkStatus: null,
  voucherStatus: null,
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    fetchCouponsStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCouponsSuccess: (state, action: PayloadAction<CouponInfo[]>) => {
      state.coupons = action.payload;
      state.isLoading = false;
    },
    fetchCouponsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    selectCoupon: (state, action: PayloadAction<string>) => {
      state.selectedCouponId = action.payload;
    },
    clearSelection: (state) => {
      state.selectedCouponId = null;
    },
    addCoupon: (state, action: PayloadAction<CouponInfo>) => {
      state.coupons.push(action.payload);
    },
    removeCoupon: (state) => {
      state.currentcoupon = initialState.currentcoupon;
      state.checkStatus = initialState.checkStatus;
      state.voucherStatus = initialState.voucherStatus;
    },
    updateCoupon: (state, action: PayloadAction<any>) => {
      state.currentcoupon = action.payload.currentcoupon;
      state.checkStatus = action.payload.checkStatus;
    },
    updateVoucherStatus: (state, action: any) => {
      state.voucherStatus = action.payload;
    },
  },
});

export const {
  fetchCouponsStart,
  fetchCouponsSuccess,
  fetchCouponsFailure,
  selectCoupon,
  clearSelection,
  addCoupon,
  removeCoupon,
  updateCoupon,
  updateVoucherStatus,
} = couponSlice.actions;

export default couponSlice.reducer;
