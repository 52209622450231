export const MuiDialog = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: "1.3rem",
        fontWeight: 600,
        color: "#111",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        // fontSize: "1rem",
        // color: "#111",
      },
    },
  },
};
