import commonReducer from "./commonSlice";
import counterReducer from "./counterSlice";
import couponReducer from "./couponSlice";
import modalReducer from "./modalSlice";
import productReducer from "./productSlice";
import snackbarReducer from "./snackbarSlice";
import userReducer from "./userSlice";

export const reducer = {
  counter: counterReducer,
  common: commonReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  product: productReducer,
  coupon: couponReducer,
  modal: modalReducer,
};
