export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent",
  gray: {
    // 숫자가 커질수록, 색이 진해짐
    dark: {
      100: "#F5F5F5",
    },
    // 숫자가 커질수록, 색이 연해짐
    light: {
      100: "#F5F5F5",
    },
    100: "#F5F5F5",
    200: "#E5E5E5",
    300: "#D4D4D4",
    400: "#A3A3A3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#171717",
  },
  primary1: "#1565c0",
  primary2: "#0A3D62",
  primary3: "#778899",
  secondary1: "#E4A952",
  secondary2: "#E5BC7D",
  secondary3: "#ECD0A6",
  warning: "#F51C4B",
  error: "#d42525",
  success: "#16A34A",
  info: "#3B82F6",
  submit: "#3B82F6",
  attention: "#68B2D2",
  disable: "#DDDDDD",
  status: {
    success: "#16A34A",
    pending: "#E4A952",
    unusable: "#8E8E8E",
  },
  hover: {
    primary1: "#304962",
    primary2: "#38617E",
    primary3: "#8F9DAA",
    secondary1: "#E6B872",
    secondary2: "#E8C694",
    secondary3: "#EDD6B5",
    warning: "#F51C4B",
    error: "#d42525",
    success: "#149343",
    info: "#3575DD",
    submit: "#3575DD",
    attention: "#5EA0BD",
  },
};
