import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Gender } from "types/account";

export interface UserState {
  isLogin: undefined | boolean;
  user: {
    email: string;
    name: string;
    phoneNumber: string;
    gender: Gender | undefined;
    birth: string;
    id: number;
  };
}

const initialState: UserState = {
  isLogin: undefined,
  user: {
    email: "",
    name: "",
    phoneNumber: "",
    gender: undefined,
    birth: "",
    id: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
      if (!action.payload) {
        state.user = initialState.user;
      }
    },
    updateUser: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      if (name in state.user) {
        (state.user as any)[name] = value;
      }
    },

    updateProfile: (state, action: PayloadAction<any>) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { updateIsLogin, updateUser, updateProfile } = userSlice.actions;

export default userSlice.reducer;
