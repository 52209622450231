import { createSlice } from "@reduxjs/toolkit";

export interface Props {
  type: "loginModal" | undefined;
}

const initialState: Props = {
  type: undefined,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state: any, action) => {
      state.type = action.payload;
    },
    hideModal: (state) => {
      state.type = undefined;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
