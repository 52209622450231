"use client";
// react query는 server 컴포넌트에서는 동작하지 않음으로 따로 빼서 관리
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";

export default function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // React Query는 내부적으로 queryClient를 사용하여 각종 상태를 저장하고, 부가 기능을 제공합니다.

  // Lazy initialization 기법
  // 복잡한 초기 상태를 생성할 때, 불필요한 재생성을 피할 수 있습니다.
  // 초기렌더링 동안에만 값을 생성하고, 이후에는 참조동일성을 유지한다.
  // useState안에 함수를 사용하면 QueryClient 인스턴스가 처음 렌더링 될때만 생성되므로 성능이 향상될 수 있다.

  // 컴포넌트가 마운트되거나, 언마운트될 때 업데이트하고 관리할 수 있다.
  const [client] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // refetch 설정은 서버요청 횟수와 관련이 있음으로 옵션설정
            refetchOnWindowFocus: false,
            // 윈도우 다시 포커스 될 때 refetch 여부
            refetchOnMount: false,
            // 데이터가 stale 상태이면 컴포넌트가 마운트 될 때 refetch
            retry: 1,
            // api 요청 실패 시 재시도 횟수
          },
        },
      })
  );

  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen />
      {children}
    </QueryClientProvider>
  );
}
