"use client";

import {
  MuiButton,
  MuiColorPalette,
  MuiDialog,
  MuiFormHelperText,
  MuiTextField,
  MuiTypography,
} from "./components";

import { createTheme } from "@mui/material/styles";

const customMuiTheme = createTheme({
  typography: MuiTypography,
  components: {
    MuiButton,
    MuiFormHelperText,
    MuiTextField,
    ...MuiDialog,
  },
  palette: MuiColorPalette,
});

export default customMuiTheme;
