import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ProductInfo {
  id: string;
  // 기타 필요한 필드들...
}

export interface ProductState {
  products: ProductInfo[];
  selectedProductId: string | null;
}

const initialState: ProductState = {
  products: [],
  selectedProductId: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<ProductInfo[]>) => {
      state.products = action.payload;
    },
    selectProduct: (state, action: PayloadAction<string>) => {
      state.selectedProductId = action.payload;
    },
    clearSelection: (state) => {
      state.selectedProductId = null;
    },
  },
});

export const { setProducts, selectProduct, clearSelection } =
  productSlice.actions;

export default productSlice.reducer;
