import { colors } from "./../theme";

export const MuiFormHelperText = {
  styleOverrides: {
    root: {
      fontSize: "0.8rem",
      marginTop: "5px",
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#000000",
      "&.Mui-error": {
        color: colors.error,
      },
    },
  },
};
