import { pretendard } from "../../customFonts/customFonts";
export const MuiTypography: any = {
  fontFamily: pretendard.style.fontFamily,
  allVariants: {
    fontFamily: pretendard.style.fontFamily,
  },
  h1: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  h2: {
    fontSize: "1.6rem",
    fontWeight: 600,
  },
  h3: {
    fontSize: "1.1rem",
    lineHeight: 1.5,
  },
  h4: {
    fontSize: "0.875rem",
  },
  h5: {
    fontSize: "0.75rem",
  },
  h6: {
    fontSize: "0.675rem",
  },
  body1: {
    lineHeight: 1.7,
  },
  body2: {
    lineHeight: 1.7,
  },
  caption: {
    fontSize: "12.5px",
  },
};
