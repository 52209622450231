"use client";

import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import customMuiTheme from "./customMuiTheme/customMuiTheme";

interface Props {
  children: React.ReactNode;
}

export default function CustomThemeProvider({ children }: Props): JSX.Element {
  return (
    <MuiThemeProvider theme={customMuiTheme}>
      <GlobalStyles
        styles={`
          :root {
            font-size: 14px;
            --font-size-lg: 2rem;
            --font-size-md: 1.5rem;
            --font-size-sm: 1rem;
          }
          @media (min-width: 600px) {
            :root {
              font-size: 15px;
              --font-size-lg: 2.75rem;
              --font-size-md: 2rem;
              --font-size-sm: 1.25rem;
            }
          }
          @media (min-width: 1024px) {
            :root {
              font-size: 16px;
              --font-size-lg: 3.5rem;
              --font-size-md: 2.5rem;
              --font-size-sm: 1.5rem;
            }
          }
          `}
      />
      {children}
    </MuiThemeProvider>
  );
}
