import "./declare";

import { colors } from "../theme";

export const MuiColorPalette = {
  primary: {
    main: "#3b82f6",
    background: "#3b82f6",
    gradient: "linear-gradient(to left, #3b82f6, #06b6d4)",
    contrastText: "#fff",
  },

  secondary: {
    main: "#06b6d4",
    // dark: "#06A7C6",
    background: "#06b6d4",
    contrastText: "#fff",
  },
  // status
  error: {
    main: colors.error,
  },
  warning: {
    main: "#ec3434",
    contrastText: "#fff",
    // light, dark, contrastText
  },
  success: {
    main: colors.success,
  },
  info: {
    main: colors.info,
  },
  // nutural
  dark: {
    main: "#222222",
    light: "#444444",
    dark: "#333333",
    contrastText: "#ffffff",
  },
  white: {
    main: "#ffffff",
    dark: "#f0f0f0",
    contrastText: "#000000",
  },
  //
  background: {
    default: "#ffffff",
    light: "#ffffff",
    dark: "#111",
    navy: "#1d2736",
    paper: "#f5f5f5",
  },
  // text: {
  //   primary: "#1f2937",
  //   secondary: "#4b5563",
  //   disabled: "#9ca3af",
  //   hint: "#6b7280",
  // },
  divider: "#e5e7eb",
  // 액션 색상
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.04,
    selected: "rgba(0, 0, 0, 0.08)",
    selectedOpacity: 0.08,
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(0, 0, 0, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  // 추가적인 브랜드 색상
  // brand: {
  //   purple: {
  //     light: "#9333ea",
  //     main: "#7c3aed",
  //     dark: "#6d28d9",
  //   },
  //   pink: {
  //     light: "#ec4899",
  //     main: "#db2777",
  //     dark: "#be185d",
  //   },
  //   orange: {
  //     light: "#f97316",
  //     main: "#ea580c",
  //     dark: "#c2410c",
  //   },
  // },
  // // 시맨틱 색상
  // semantic: {
  //   info: {
  //     light: "#3b82f6",
  //     main: "#2563eb",
  //     dark: "#1d4ed8",
  //   },
  //   success: {
  //     light: "#22c55e",
  //     main: "#16a34a",
  //     dark: "#15803d",
  //   },
  //   warning: {
  //     light: "#f59e0b",
  //     main: "#d97706",
  //     dark: "#b45309",
  //   },
  //   error: {
  //     light: "#ef4444",
  //     main: "#dc2626",
  //     dark: "#b91c1c",
  //   },
  // },
  // // 특수 용도 색상
  // chart: {
  //   color1: "#3b82f6",
  //   color2: "#22c55e",
  //   color3: "#f59e0b",
  //   color4: "#ef4444",
  //   color5: "#8b5cf6",
  //   color6: "#06b6d4",
  //   color7: "#14b8a6",
  //   color8: "#f43f5e",
  // },
};
