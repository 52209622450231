"use client";

import { useEffect } from "react";
import ReactGA from "react-ga4";

export function GAInitializer() {
  useEffect(() => {
    if (
      window.location.hostname === "dev-consulting.yeboc.kr" ||
      window.location.hostname === "localhost"
    ) {
      ReactGA.initialize("G-P33PQN4LVC");
    } else {
      ReactGA.initialize("G-J2PRSPWRPE");
    }
  }, []);

  return null;
}
