import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface SnackbarState {
  message: string;
  isOpen: boolean;
}

const initialState: SnackbarState = {
  message: "",
  isOpen: false,
};

export const showSnackbarWithTimeout = createAsyncThunk(
  "snackbar/showWithTimeout",
  async (message: string, { dispatch }) => {
    dispatch(showSnackbar(message));
    await new Promise((resolve) => setTimeout(resolve, 3000));
    dispatch(hideSnackbar());
  }
);

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.isOpen = true;
      state.message = action.payload;
    },
    hideSnackbar: (state) => {
      state.isOpen = false;
      state.message = "";
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
