import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { eUniversity } from "types/enums";

export interface Props {
  university: eUniversity | null;
  pending: boolean;
}

const initialState: Props = {
  university: null,
  pending: true,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateCommon: (state: any, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    updateUniversity: (state, action: PayloadAction<eUniversity | null>) => {
      state.university = action.payload;
    },
  },
});

export const { updateCommon, updateUniversity } = commonSlice.actions;

export default commonSlice.reducer;
