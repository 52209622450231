import "@mui/material/styles";

import { Components, Theme } from "@mui/material/styles";

export const MuiButton: Components<Theme>["MuiButton"] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      borderRadius: 3,
      fontWeight: 700,
    }),
    contained: ({ theme }) => ({
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "inherit",
        boxShadow: "none",
      },
    }),
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    containedSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    }),
    containedInherit: ({ theme }) => ({
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.grey[800],
      },
    }),
    outlined: ({ theme }) => ({
      borderWidth: 2,
      "&:hover": {
        borderWidth: 2,
      },
    }),
    // text: ({ theme }) => ({
    //   "&:hover": {
    //     backgroundColor: theme.palette.primary.light,
    //   },
    // }),
  },
  variants: [
    {
      props: { variant: "contained", color: "dark" },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.grey[900],
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: theme.palette.grey[800],
        },
      }),
    },
    {
      props: { variant: "contained", color: "white" },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.white.main,
        color: theme.palette.white.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.white.dark,
        },
      }),
    },
    {
      props: { variant: "contained", color: "warning" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: theme.palette.warning.dark,
        },
      }),
    },
  ],
};
